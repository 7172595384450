/* Markdown styling */
.markdown-body {
	box-sizing: border-box;
	min-width: 200px;
	max-width: 100%;
	margin: 0 auto;
	padding: 1.5rem;
	color: #24292e;
	background-color: #ffffff !important;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.6;
	word-wrap: break-word;
	border-radius: 6px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: 600;
	line-height: 1.25;
	color: #1a202c;
}

.markdown-body h1 {
	font-size: 2em;
	padding-bottom: 0.3em;
	border-bottom: 1px solid #eaecef;
}

.markdown-body h2 {
	font-size: 1.5em;
	padding-bottom: 0.3em;
	border-bottom: 1px solid #eaecef;
}

.markdown-body h3 {
	font-size: 1.25em;
}

.markdown-body h4 {
	font-size: 1em;
}

.markdown-body h5 {
	font-size: 0.875em;
}

.markdown-body h6 {
	font-size: 0.85em;
	color: #6a737d;
}

.markdown-body p {
	margin-top: 0;
	margin-bottom: 16px;
}

.markdown-body ul,
.markdown-body ol {
	padding-left: 2em;
	margin-top: 0;
	margin-bottom: 16px;
}

.markdown-body ul {
	list-style-type: disc;
}

.markdown-body ol {
	list-style-type: decimal;
}

.markdown-body li {
	margin-top: 0.25em;
}

.markdown-body li + li {
	margin-top: 0.25em;
}

.markdown-body blockquote {
	padding: 0 1em;
	color: #6a737d;
	border-left: 0.25em solid #dfe2e5;
	margin: 0 0 16px 0;
}

.markdown-body code {
	padding: 0.2em 0.4em;
	margin: 0;
	font-size: 85%;
	background-color: rgba(27, 31, 35, 0.05);
	border-radius: 3px;
	font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
}

.markdown-body pre {
	word-wrap: normal;
	padding: 16px;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #f6f8fa;
	border-radius: 3px;
	margin-bottom: 16px;
}

.markdown-body pre code {
	display: inline;
	max-width: auto;
	padding: 0;
	margin: 0;
	overflow: visible;
	line-height: inherit;
	word-wrap: normal;
	background-color: transparent;
	border: 0;
}

/* Dark mode styles - still using light background for better readability */
.dark-mode .markdown-body,
.markdown-body-dark {
	color: #24292e;
	background-color: #ffffff !important;
	border: 1px solid #30363d;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dark-mode .markdown-body h1,
.dark-mode .markdown-body h2,
.dark-mode .markdown-body h3,
.dark-mode .markdown-body h4,
.dark-mode .markdown-body h5,
.dark-mode .markdown-body h6,
.markdown-body-dark h1,
.markdown-body-dark h2,
.markdown-body-dark h3,
.markdown-body-dark h4,
.markdown-body-dark h5,
.markdown-body-dark h6 {
	color: #1a202c;
}

.dark-mode .markdown-body h1,
.dark-mode .markdown-body h2,
.markdown-body-dark h1,
.markdown-body-dark h2 {
	border-bottom: 1px solid #eaecef;
}

.dark-mode .markdown-body h6,
.markdown-body-dark h6 {
	color: #6a737d;
}

.dark-mode .markdown-body blockquote,
.markdown-body-dark blockquote {
	color: #6a737d;
	border-left: 0.25em solid #dfe2e5;
}

.dark-mode .markdown-body code,
.markdown-body-dark code {
	background-color: rgba(27, 31, 35, 0.05);
}

.dark-mode .markdown-body pre,
.markdown-body-dark pre {
	background-color: #f6f8fa;
}

/* Add spacing between elements */
.markdown-body > *:first-child {
	margin-top: 0 !important;
}

.markdown-body > *:last-child {
	margin-bottom: 0 !important;
}

/* Table styles */
.markdown-body table {
	display: block;
	width: 100%;
	overflow: auto;
	margin-top: 0;
	margin-bottom: 16px;
	border-spacing: 0;
	border-collapse: collapse;
}

.markdown-body table th {
	font-weight: 600;
}

.markdown-body table th,
.markdown-body table td {
	padding: 6px 13px;
	border: 1px solid #dfe2e5;
}

.markdown-body table tr {
	background-color: #fff;
	border-top: 1px solid #c6cbd1;
}

.markdown-body table tr:nth-child(2n) {
	background-color: #f6f8fa;
}

.dark-mode .markdown-body table th,
.dark-mode .markdown-body table td,
.markdown-body-dark table th,
.markdown-body-dark table td {
	border: 1px solid #dfe2e5;
}

.dark-mode .markdown-body table tr,
.markdown-body-dark table tr {
	background-color: #fff;
	border-top: 1px solid #c6cbd1;
}

.dark-mode .markdown-body table tr:nth-child(2n),
.markdown-body-dark table tr:nth-child(2n) {
	background-color: #f6f8fa;
}
